.auth-layout .auth-layout-row {
  height: 100vh;
}

.auth-layout .auth-layout-row .auth-left {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-layout .auth-layout-row .auth-right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
