.form-row {
  padding: 1.2rem 0px 1.2rem;
}

.radio {
  padding-bottom: 10px;
}

.first-occ {
  padding-top: 0px !important;
}

.first-sec-side-box {
  padding-bottom: 10px !important;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  accent-color: black !important;
  border: 10000px solid #020914 !important;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  border: 2px solid #020914 !important;
  cursor: pointer;
}

.group-add-button {
  bottom: 0px !important;
  position: absolute;
}

.group-add-button > button {
  width: 144px;
  height: 40px;
  background-color: white;
  border: 0.5px solid #047eb1;
  border-radius: 5px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #047eb1;
}

.group-add-button > button:hover {
  background-color: #047eb1;
  color: white;
  cursor: pointer;
}

.check-box-text {
  margin-top: 0.2em;
  bottom: 0px !important;
}

.icon-side-box {
  padding-right: 10px !important;
  padding-left: 0px !important;
  padding-top: 5px !important;
  color: #000000;
}
.rdw-editor-toolbar {
  background: #f2f2f2 !important;
}

.rdw-option-wrapper {
  padding: 15px !important;
  background: #f2f2f2 !important;
}

.text-field-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
}

.text-field-label-custom {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 10px;
}

.heading-section {
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
  font-size: 1.5rem;
  color: #0abbf2;
  margin-bottom: 40px;
  margin-right: 100px;
}
.heading-drawer {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 20px;
}

.para-drawer {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #000000;
}

.para-drawer-section {
  padding-bottom: 20px;
}

.button-dawer-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.drawer-button {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12.7417px;
  line-height: 16px;
  letter-spacing: 0.238906px;
  color: #047eb1;
  background: #ffffff;
  border: 0.796354px solid #047eb1;
  border-radius: 3.18542px;
  padding: 5px 5px;
  width: 30%;
}

.link-section {
  color: black;
}

.heading-style {
  display: flex;
}

.vector-logo {
  margin-top: 15px;
}

.drawer-button:hover {
  cursor: pointer;
  background-color: #cce6ff;
}

.public-DraftEditorPlaceholder-root {
  padding-left: 15px;
}

.chapter-button {
  width: 55%;
  height: 40px;
  left: 196px;
  top: 2414px;

  background: #0abbf2;
  border: 1px solid #0abbf2;
  border-radius: 4px;

  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;

  color: #ffffff;
}

.chapter-cancel {
  width: 40%;
  height: 21px;

  border: none;
  background-color: transparent;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #666666;
}

.chapter-button:hover {
  cursor: pointer;
  background: #046289;
}

.chapter-cancel:hover {
  cursor: pointer;
  color: #046289;
}

.radio-options {
  display: flex !important;
}

.pointsTable {
  border: 1px solid #000000;
  border-radius: 5px !important;
  width: 35%;
  margin-top: 20px;
}

.table-top-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */
  color: #000000;
  padding: 10px;
}

.pointsTable th {
  background: #ccf3ff;
  border: 1px solid #3370ad;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #004992;
}

.pointsTable table {
  width: 100%;
}

.pointsTable td {
  border: 1px solid #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  text-align: center;
}

.input-fld {
  border: none;
  height: 100%;
  width: 100%;
}

.points-heading {
  min-width: 100px;
}

.side-point-section {
  margin-top: 10px;
}

/* .ant-picker {
    width: 350px !important;
} */
.td-class {
  max-width: 30px;
}
.FormikRangePickerStyles {
  width: 500px !important;
}

.h40 {
  height: 40px !important;
}
.align-bottom {
  align-items: flex-end !important;
}
