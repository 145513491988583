.heading-style {
    display: flex;
}

.heading-section {
    font-weight: 700;
    font-family: 'Plus Jakarta Sans';
    font-size: 32px;
    color: #047EB1;
    margin-bottom: 40px;
    margin-right: 10px;

}

.vector-tick {
    margin-top: 20px;
}

.radioTitle {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    letter-spacing: 0.3px;
}

.radioOptions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    letter-spacing: 0.3px;
    gap: 20px;
}

.flightsDivisionTable .ant-radio-checked .ant-radio-inner {
    border-color: #000;
}

.flightsDivisionTable .ant-radio-checked .ant-radio-inner:after {
    background-color: #000;
}

.flightsDivisionTable .flightsRemoveButton,
.flightsRemoveButton:hover,
.flightsRemoveButton:focus {
    font-family: Montserrat;
    font-weight: 500;
    min-width: 67px;
    border: none;
    font-size: 16px;
    color: #D11F1F;
    margin-top: 4px;
}

.flightsDivisionTable {
    display: flex;
    flex-direction: column;
}

.flightsTableButtonSection {
    display: flex;
    justify-content: flex-end;
}

.divisions-table-title {
    color: black;
    background-color: #fff;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    border: 1px solid black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 30px;
    width: 48%;
    height: 50px;
}

.divisions-table-columns {
    background: #047EB1;
    color: #fff;
    text-align: center;
    font-weight: 700;
    width: 48%;
    height: 60px;
}

.divisions-table-header-text {
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FDselect-gender {
    width: 128px;
    text-align: center;
    height: 50px;
    border: 1px solid rgb(0, 0, 0);
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
}

.FDselect-gender .ant-select-selection-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
}

.flightsDivisionTable .ant-typography {
    color: #fff;
    background-color: #047EB1;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 16px;
    width: 100px;
}

.divisionsTableData .ant-row {
    width: 48%;
}

.input-container input,
select {
    background: #fff;
    border: 1px solid #789;
    border-radius: 0;
    font-weight: 700;
    height: 44px;
    padding: 10px 16px;
    text-align: center;
    width: 100%;
}

.flightsDivisionTable .addDivisionsButton,
.addDivisionsButton:hover,
.addDivisionsButton:focus {
    color: #047EB1;
    background: none;
    display: flex;
    align-items: center;
    min-height: 40px;
    justify-content: center;
    border-color: #047EB1;
    border-radius: 3px;
    margin-right: 9px;
}

.SponsorFooterBtnSection {
    display: flex;
    gap: 24px;
    margin-left: 10px;
    margin-top: 50px;
}

.SponsorFooterBtnSection .FlightsSaveButton {
    width: 200px;
    height: 50px;
    color: white;
    background-color: #047EB1;
    font-family: 'Plus Jakarta Sans';
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SponsorFooterBtnSection .FlightsSaveButton {
    width: 200px;
    height: 50px;
    color: white;
    background-color: #047EB1;
    font-family: 'Plus Jakarta Sans';
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fdCancelButton {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    width: 50px;
    height: 50px;
    border: none;
    font-size: 14px;
}

.rewards-table-wrapper {
    width: 500px;
    margin-bottom: 30px;
}

.rewards-table-wrapper .rewards-table-row {
    display: flex;
    flex: 1;
}

.flightsDivisionTable .FDerror-wrapper {
    color: #E74C3C;
    font-size: 10px;
    position: absolute;
    bottom: 0;
}

.flightsDivisionTable .error-wrapper {
    color: #E74C3C;
    font-size: 10px;
    position: absolute;
}

.flightsDivisionTable .tableFieldWrapper {
    position: relative;
}

.input-container {
    flex: 1;
}

.input-container input,
select {
    padding: 10px 16px;
    width: 100%;
    text-align: center;
    border-radius: 0;
    font-weight: bold;
    border: 1px solid lightslategray;
    height: 44px;
    background: #fff;
}

.input-container select {
    cursor: pointer;
}

.addDivisionsDiv .ant-row,
.ant-row:after,
.ant-row:before {
    justify-content: flex-end;
    margin-top: 50px;
    width: 49%;
}

.flightsDivisionTable textarea.ant-input {
    border: 1px solid #000000 !important;
    text-align: center;
    height: 50px;
}

.flightsDivisionTable .ant-form-item .ant-radio-wrapper {
    margin: 0 80px 0 0;
    letter-spacing: 0.3px;
}

.flightsDivisionTable .text-field-label {
    margin-bottom: 10px;
    letter-spacing: 0.3px;
}

.formikTextField {
    height: 40px;
}

/* dashboard stylings */

.fd-add-container .add-new-fd-image {
    width: 84px;
}

.fd-add-container .add-new-fd-text {
    text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    color: #000000;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-top: 20px;
}

.fd-add-container-data {
    min-height: 250px;
    border: 1px solid #A6A8AB;
}

.fd-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 45px;
}

.fd-icon-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    align-items: center;
    gap: 20px;
}

.fd-search-icon {
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    background: #F0F0F0;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A0A0A0;
}

.fd-search-icon-container {
    margin-top: 7px;
}

.fd-search-icon-container .ant-input-affix-wrapper {
    height: 35px;
    color: #A0A0A0;
}

.fd-search-icon-container .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    border: 2px solid #A0A0A0;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    background: #F0F0F0;
    color: #A0A0A0;
}


.fd-icon {
    width: 30px;
    height: 30px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A0A0A0;
}

.fd-icon:hover,
.fd-icon:focus {
    cursor: pointer;
}

.fdcard-container {
    border: 1px solid #A6A8AB;
    height: 0;
    overflow: hidden;
    background: white;
    position: relative;
    padding-top: 56.25%;
    border-radius: 10px;
    min-height: 250px;
}

.fdcard-container .fdcard-container-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 32px;
}

.fdcard-container .ant-card-head {
    border: none;
}


.FDchanges-button-container {
    width: auto;
    height: 40px;
}

.changes-button {
    border: 0px;
    background: #ffffff;
    cursor: pointer;
    font-size: 12px;
    width: 150px;
    text-align: start;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.03em;
    display: flex;
    justify-content: start;
    align-items: center;

}

.changes-button:hover {
    background: #cde5ef;
}

.changes-container {
    border: 0px;
    background-color: #cde5ef;
    color: #047eb1;
    cursor: pointer;
}

.fdcard-container-inside .ant-card-head-title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 20px;
    color: #6F6F6F;
    margin-top: 20px;
}

.fdcard-container-inside .card-text {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 20px;
    color: #6F6F6F;
}

.fdGreyCancelButton,
.fdGreyCancelButton:hover,
.fdGreyCancelButton:focus,
.fdGreyCancelButton::after {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    width: 100px;
    height: 50px;
    border: none;
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: center;
    font-size: 14px;
    color: #666666;
}

.fdCancelButton,
.fdCancelButton:hover,
.fdCancelButton:focus,
.fdCancelButton::after {
    color: #047EB1;
    background: none;
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: center;
    border-radius: 5px;
    width: 200px;
    border: 1px solid #047EB1;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.4px;
}

.fdActiveTab {
    background-color: #AFF8D9;
    color: #198658;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 28px;
    border-radius: 5px;
}

/* delete modal Css */
.FDDeleteModal .ant-modal-body {
    text-align: center;
    padding: 52px 32px 52px;
}

.FDDeleteModal .ant-modal-confirm-body {
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-weight: bold;
    font-size: 36px;
    line-height: 45.36px;
    margin-top: 20px;
}

.FDDeleteModal .ant-modal-confirm-body .ant-modal-confirm-title {
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-weight: bold;
    font-size: 36px;
    line-height: 45.36px;
    margin-top: 20px;
}

.FDDeleteModal .ant-modal-confirm-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
}

.FDDeleteModal .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content .ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-weight: 400;
    font-size: 18px;
    line-height: 22.68px;
}

.FDDeleteModal .anticon svg {
    display: none;
}

.FDDeleteModal .ant-modal-confirm-btns {
    align-items: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FDDeleteModal .ant-btn-primary {
    background-color: #B40000 !important;
    border-radius: 4px;
}

.FDDeleteModal .ant-modal-confirm-btns>.ant-btn.ant-btn-default {
    width: 100px;
    height: 45px;

    border: 1px solid #A0A0A0;
    border-radius: 4px;
}

.FDDeleteModal .ant-modal-confirm-btns>.ant-btn.ant-btn-primary {
    width: 100px;
    height: 45px;

    border: 1px solid #A0A0A0;
    border-radius: 4px;
}

/* collapsable search icon styles  */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.content {
    width: 600px;
    position: relative;
}

.search {
    --size: 42px;
    border: 2px solid #A0A0A0;
    display: flex;
    overflow: hidden;
    font-size: 1em;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin-left: auto;
    padding: 3px;

    @media (prefers-reduced-motion: no-preference) {
        transition: width 450ms;
    }
}

.search__input {
    border: 0;
    padding: .25em 1em;
    flex-grow: 1;
    outline: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent;
    opacity: 0;
    cursor: pointer;
}

.search__input::placeholder {
    color: #A0A0A0;
}

.search__submit {
    font-size: 1.5rem;
    margin-left: auto;
    background: #F0F0F0;
    border: 0;
    cursor: pointer;
    transition: background 200ms ease-out;
    width: calc(var(--size) - 10px);
    height: calc(var(--size) - 10px);
    color: #A0A0A0;
}

.search:focus-within {
    width: 100%;
}

.search__input {
    opacity: 1;
    z-index: initial;
    cursor: initial;
    width: calc(100% - var(--size));
}

/*GCB-506*/
.flights-divisions .ant-select-selector {
    border: 1px solid #000000 !important;
    border-radius: unset;
    width: 128px !important;
    height: 50px !important;
    padding: unset !important;
}


.flexbox-fix {
    display: none !important;
}

.colour-input {
    box-sizing: border-box;
    border: 1px solid #000000;
    height: 100%;
    text-align: center;
    padding-top: 10%;
    cursor: pointer;
}

.ant-popover-open {
    box-sizing: border-box;
    border-radius: 4px;
    width: 24px !important;
    height: 24px !important;
}

.ant-popover-close {
    display: none !important;
}

.colour-input>*:first-child {
    box-sizing: border-box;
    border-radius: 4px;
    width: 24px !important;
    height: 24px !important;

}

.colour-input-data {
    margin-left: 10px !important;

}

.compact-picker {
    width: 120px !important;

}
.viewmode-fields .ant-input[disabled] {
    color: #000000 !important;
}

.viewmode-fields,
.ant-radio-disabled+span {
    color: #000000 !important;
}