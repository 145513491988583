.accounting-date-picker {
  border-color: #2373c56e !important;
}
.accounting-verticle-line {
  border-right: 1px solid #047eb1 !important;
  top: 0px;
  height: 48px;
  right: 40px;
}
.accouting-filter-chapter {
  padding: 11px 4px;
  border: 1px solid #047eb1 !important;
  color: #047eb1 !important;
}
.accouting-filter-chapter {
  font-size: 16px;
}
/* .accounting-price-number {
  display: flex;
  justify-content: space-between;
} */
.accounting-price-number>b {
  padding-right: 0.75rem;
  font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
}
.accounting-price-number > span {
  margin-top: auto;
  margin-bottom: 10px;
}
.accounting-card-icon > svg {
  height: 30px;
  width: 30px;
}
.accounting-card-icon {
  font-size: 30px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  /* background-color: #c5dffe; */
  }
  
  .accounting-card-icon-revenue {
  background-color: #c5dffe;
}

.accounting-menu {
  display: flex;
  flex-direction: row;
  position: relative;
}
.accounting-menu > .line {
  border-bottom: 1px solid #cccccc;
  position: absolute;
  width: 100%;
  bottom: 2px;
  z-index: 0;
}
.accounting-menu-button {
  color: #047eb1;
  background-color: #e0eeff;
  padding: 1rem 2rem 1rem 2rem;
}
.accounting-menu-button-selected {
  padding: 1rem 2rem 10px 2rem !important;
  border-bottom: 5px solid #047eb1;
  font-weight: bold;
  z-index: 1;
}
.max-width-280 {
  max-width: 280px;
}

.balence-Revenue-title {
  background-color: #198658;
}
.balence-Expense-title {
  background-color: #f28f1b;
}
.balence-edit-clock {
  color: #03b6d6;
  font-size: 24px;
  border: 1px solid #03b6d6;
  padding: 8px;
  margin-bottom: -0.75rem;
}
.balence-edit-price:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.profitLoss .ant-card-body {
  background: #caefe0;
  border-radius: 10px;
  padding-top: 140px;
  min-width: 300px;
  margin-top: 50px;
}
.profitLoss .ant-statistic-title {
  font-weight: 700;
  color: #000;
  font-size: 16px;
}

.profitLoss .ant-statistic-content {
  font-weight: 700;
  font-size: 40px;
}

.budgetingLegend {
  display: flex;
  justify-content: end;
  gap: 50px;
}

.budgetingLegend .legendContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
  align-items: center;
}

.budgetingLegend .legendContainer .legend {
  height: 12px;
  width: 12px;
}

.commentModal .cancelIcon {
  text-align: left;
  border: 2px solid #a6a8ab;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 3px;
  color: #a6a8ab;
  margin-left: 16px;
}

.commentModal .cancelIcon svg {
  width: 16px;
  height: 16px;
}

.balence-tree-wrapper .ant-tree-node-content-wrapper {
  width: 100%;
}
.balence-tree-wrapper .ant-tree-treenode {
  width: 100%;
}
.balence-tree-wrapper .ant-tree-switcher .anticon {
  vertical-align: -15px;
}
.balence-tree-wrapper .ant-tree-switcher-leaf-line {
  margin-top: 14px;
}


.budgetContainer{
  border:1px solid #A0A0A0;
  border-radius: 4px;
  padding: 16px;
  margin: 21px;
}

.budgetContainer .ant-tabs-tab{
  padding: 2px;
  font-size: 12px;
  color:#959595;
}

.budgetContainer .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap{
  justify-content: center;
}

.budgetContainer .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list{
  padding:1px;
  border:1px solid #047EB1;
  border-radius: 3px;
}

.budgetContainer .ant-tabs-top>.ant-tabs-nav:before{
  border:none;
}

.budgetContainer .ant-tabs-ink-bar {
  display: none;
}

.budgetContainer .ant-tabs-tab-active{
  padding: 0 !important;
}

.budgetContainer .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  background: #047EB1;
  color:#fff;
  padding: 5px 14px;
  font-weight: 700;
  border-radius: 3px;
}

.budgetContainer .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.budgetContainer .ant-tabs-tab-btn{
  padding: 0 24px;
}

.budgetContainer .divider{
  border-top:2px solid #A0A0A0;
  margin:10px 0px;
}

.budgetContainer .retalPrice{
  border:1px solid #A6A8AB;
  border-radius: 4px;
  background: #ECECECB2;
  color: #000000A6;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 8px;
}

.accounting-header-with-filter .ant-select-selection-item {
  margin: auto;
}
.accounting-header-with-filter .ant-select-selector {
  border: 1px solid #047eb1 !important;
  color: #000000;
  font-weight: bold;
}
.accounting-header-with-filter .ant-select-selection-item {
  font-weight: bold;
  min-width: 150px;
}
.accounting-header-with-filter .ant-select-arrow {
  color: #000000;
  right: 15px;
}
.accounting-header-with-filter .ant-select-selection-search {
  margin: auto;
  font-weight: 400 !important;
}
.graph-section {
  width: 40px;
}

.graph-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.accounting-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.decimal-part {
  font-size: 22px;
}
.no-decimal-part {
  line-height: 60px;
}

.accounting-card {
  width: 268px;
  min-width: 220px;
}
.b-r-12 {
  border-radius: 12px;
}
.payment-methods-stripe {
  padding: 30px;
  border: 1px solid #635BFF;
  border-radius: 10px;
  margin-top: 30px;
}

.payment-stripe-header {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
  margin-top: 10px
}

.payment-stripe-text {
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 10px;
  margin-top: 10px
}

.accouting-filter-chapter > div {
  max-width: 256px;
}