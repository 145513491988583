.card-heigth {
  height: 200px;
}
.card-add-icon-antd {
  font-size: 42px;
  font-weight: 300 !important;
  color: black !important;
  opacity: 0.5;
}
.card-add-icon-text {
    color:#047EB1;
    font-weight: 800;
    font-size: 16px;
}
.mt-auto {
  margin-top: auto;
}
.w-150 {
  width: 150px;
}
.scoring-input-wrapper .ant-checkbox-wrapper span {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 12px;
}
.input48 .ant-input {
  height: 42px;
}
.mrb-4{
  margin-bottom: 1rem;
  margin-right: 1rem;
}