.shadowLayout {
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    font-family: Plus Jakarta Sans;
}

.csvButton {
    background: #E3F0FF !important;
    font-family: "Plus Jakarta Sans";
    color:#047EB1 !important;
    border: 1px solid #E3F0FF !important;
    margin: 1rem;
    font-weight: 700 !important;
    border-radius: 5px;
    height: 40px;
    padding: 0.5rem 1.5rem;
}
.csvButton:hover {
    border: 1px solid #047EB1 !important;
}

.uploadButton {
    background: #CCE6FF;
    border-radius: 4px;
    border-color: #CCE6FF;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #004992;
    height: 34px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.uploadButton:hover {
    background-color: #b0d8fd;
    border: unset;
}
.uploadButton > span > svg {
    width: 20px;
    height: auto;
}
.button {
    background: #E3F0FF;
    font-family: "Plus Jakarta Sans";
    color: #fff;
    border: 0px solid;
    margin: 1rem;
    font-weight: 700;
    border-radius: 5px;
    height: fit-content;
    padding: 1rem 3rem;
}