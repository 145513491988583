.com-form-container {
  margin-left: 10px;
}

.com-form-container .ant-select-arrow {
  color: #000000;
}

.com-form-container p {
  padding-bottom: 0;
  margin-bottom: 6px;
}

.com-form-container .ant-input:placeholder-shown {
  border: 1px solid #a6a8ab;
}

.com-form-container
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #a6a8ab;
  height: 50px;
  border-radius: 5px;
}

.com-form-container .form-date-field-custom {
  height: 50px;
}

.com-form-container .ant-checkbox-wrapper span {
  font-size: 14px;
}

.com-form-container .ant-select-arrow {
  top: 50%;
}

.com-form-container .ant-select-selector {
  display: flex;
  align-items: center;
}

.com-form-container .ant-radio {
  transform: scale(1.2);
}
.com-form-container .ant-btn-primary {
  width: 100%;
}
.ant-notification-notice-message {
  font-weight: 800;
  margin: 0 0 0.25rem 2.5rem !important;
}

.com-form-container .text-field-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #000000;
  padding-bottom: 0px !important;
}

.ant-notification-notice-description {
  margin-left: 2.5rem !important;
}

.ant-notification-notice-close {
  display: none;
}
.ant-form-item-explain-error {
  color: #bf0000 !important;
}
.ant-notification-notice-icon-error {
  color: #bf0000 !important;
  margin-top: 1px;
}

.ant-notification-notice-icon-success {
  color: #198658 !important;
  margin-top: 1px;
}

.pointer {
  cursor: pointer;
}
.pointer-disabled {
  cursor: not-allowed;
}
.event-disabled {
  pointer-events: none;
}
.btnSpin .ant-spin span > i {
  background-color: white !important;
}

.input-name-padding {
  width: 100%;
  padding: 8px 12px;
}

.submitBtn-wrapper {
  margin-top: 6rem;
}

.raw-button {
  font-family: "Plus Jakarta Sans";
  color: #fff;
  border: 0px solid;
  font-weight: 700;
  border-radius: 5px;
  height: fit-content;
  padding: 1rem 3rem;
}
.horizental-scroll {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-center {
  display: flex;
  align-items: center;
}
.d-none {
  display: none;
}
.opacity-0 {
  opacity: 0;
}
.raw-button:hover {
  color: #fff;
}
.bg-w {
  background-color: #fff;
}

.text-w {
  color: #fff !important;
}
.text-cap {
  text-transform: capitalize;
}
.text-up {
  text-transform: uppercase;
}

.text-b {
  color: black !important;
}
.text-red {
  color: red;
}
.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.font-b {
  font-weight: 700;
}

.pr-2 {
  padding-right: 0.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.m-8 {
  margin: 2rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.ml-0 {
  margin-left: 0px !important;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-16 {
  margin-right: 4rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}
.ml-8 {
  margin-left: 2rem;
}
.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt--1 {
  margin-top: -1px;
}

.ml--1 {
  margin-left: -1px;
}

.ml--2 {
  margin-left: -2px;
}

.h-100 {
  height: 100%;
}

.h-full {
  height: 100vh;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-100 {
  width: 100%;
}

.w-full {
  width: 100vw;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
.mr-auto {
  margin-right: auto;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.p-abs {
  position: absolute;
}

.p-rel {
  position: relative;
}

.drawer-button:hover {
  cursor: pointer;
  background-color: #cce6ff;
}

.rdw-editor-main {
  padding: 0.5rem 1rem;
}

.b-r-5 {
  border-radius: 5px;
}

.b-r-3 {
  border-radius: 3px;
}
.border-dash {
  border: 1px dashed #a6a8ab;
}
.text-underline {
  text-decoration: underline;
}
.b-skyBlue {
  border: 1px solid #047eb1;
}

.text-skyBlue {
  color: #047eb1;
}
.text-14 {
  font-size: 14px;
}
.auth-text-color {
  color: #9fa2b4;
}
.bg-skyBlue {
  background-color: #047eb1;
}

.d-border {
  border: 1px solid #a6a8ab;
}

.r-border {
  border-right: 1px solid #a6a8ab;
}

.border-none {
  border: 0px !important;
}

.border-none-div > div {
  border: 0px !important;
}

.remove-a {
  text-decoration: none;
  color: #000;
}

/* place holder */
.color-PH {
  color: #a6a8ab;
}

input.placeholder-color::placeholder {
  color: #999999;
}

.h-50 {
  height: 50px;
}

.b-50 {
  border-radius: 50%;
}

.w-half {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.line-height1 {
  line-height: 1;
}

.uploadedFileName {
  color: #004992;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  padding: 0.5rem;
  /* text-decoration: underline; */
}

.opacity-5 {
  opacity: 0.5;
}

.bg-decent {
  background-color: #e5f1ff;
}

.decent-border {
  border: 1px solid #047eb1;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-42 {
  font-size: 42px !important;
}

/* input filed heigth 50px */
.input50 .form-input-field-custom {
  height: 50px;
}

.input50 .ant-input {
  height: 50px !important;
}

.input50 .input-name-padding {
  width: 100%;
  border-radius: 4px;
  height: 50px;
}

.input50 .form-input-field-custom {
  height: 50px;
}

.input50 .ant-select-selector {
  height: 48px !important;
  align-items: center !important;
}

/* checkbox black */
.black-radio .ant-radio-checked .ant-radio-inner:after {
  background: #000000;
}

.black-radio .ant-radio-checked .ant-radio-inner {
  border-color: #000000;
}

.black-radio .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #000000;
}

.black-radio .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: #000;
}

.black-radio .ant-checkbox-checked .ant-checkbox-inner:focus-visible {
  background-color: transparent;
  border-color: #000;
}
.black-radio .ant-switch-checked {
  background-color: Gray;
}
.input-focus-none:focus-visible {
  border: 0px;
  outline: unset;
}

.dropdown-focus-none:focus-visible {
  border: 0px;
  outline: 0px solid !important;
}

.dropdown-focus-none > div:focus-visible {
  border: 0px;
  outline: 0px solid !important;
}

.d-box-shadow {
  box-shadow: 0px 1.01838px 5.0919px rgba(0, 0, 0, 0.25);
}

.hover-grow-span {
  transition: transform 0.3s ease;
}

.hover-grow-span:hover {
  transform: scale(1.05);
}

.hover-grow {
  transition: transform 0.3s ease;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.smooth-scrollable-container {
  scroll-behavior: smooth;
}

.dropdown-align > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}

.phone-error {
  margin-top: -21px;
  color: #e74c3c;
  font-size: 14px;
}
.pt-1 {
  padding-top: 0.25rem;
}
.all-caps {
  text-transform: uppercase;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.forgot-box-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0.16px 9.54074px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: solid;
  border-width: 0.5px;
  border-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.scale1-3 {
  transform: scale(1.3);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #a6a8ab;
  height: 50px;
  border-radius: 5px;
}


.finalizeScoresModal {
  text-align: center;
}

.finalizeScoresModal .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: row-reverse;
  margin-top: 50px;
}

.finalizeScoresModal .ant-modal-confirm-title {
  font-size: 36px;
  font-weight: 700;
}

.finalizeScoresModal .ant-modal-confirm-content {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
}

.finalizeScoresModal .ant-btn{
  border-radius: 4px;
  border:none;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  width: 150px;
}

.finalizeScoresModal .ant-btn-primary{
  background: #0ABBF2;
  color: #fff;
  
}

.finalizeScoresModal .ant-btn-default{
  border:1px solid #A0A0A0;
}

.listView .ant-table-container table>thead>tr:first-child th:first-child {
  text-align: left;
}

.listView .ant-table-tbody>tr>td:first-child{
  border-left: 1px solid #D5D5D5;
}

.listView .ant-table-tbody>tr>td:last-child{
  border-right: 1px solid #D5D5D5;
}

.csv-upload-wrapper > div {
  width: 100%;
}