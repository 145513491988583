/* Main layout styles */

.r-admin-sider-layout > .r-admin-sider {
  background: #047eb1;
  height: 100vh;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.r-admin-sider-layout > .r-admin-sider .ant-menu {
  border: none;
  background: transparent;
}

.r-admin-sider-layout > .r-admin-sider .logo img {
  width: 60px;
  margin-top: -2px;
}

.r-admin-sider-layout > .r-admin-sider .logo {
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-layout-sider-collapsed.r-admin-sider ul li {
  padding-top: 5px !important;
}

.r-admin-sider ul li .ant-menu-title-content {
  font-size: 5px;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) {
  margin-top: 52px;
}

.r-admin-sider ul .ant-menu-item {
  color: #047eb1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 2px;
}

.r-admin-sider ul .ant-menu-item svg {
  fill: #fefefe;
}

.r-admin-sider ul .ant-menu-item:hover {
  background-color: rgba(250, 252, 255, 0.25);
  border-radius: 4px;
  color: #fff;
}

.r-admin-sider ul .ant-menu-item:active {
  background-color: rgba(250, 252, 255, 0.25);
  border-radius: 4px;
  color: #fff;
}

.r-admin-sider ul .ant-menu-item:focus-visible {
  background-color: rgba(250, 252, 255, 0.25);
  border-radius: 4px;
  color: #fff;
}

.r-admin-sider ul .ant-menu-item:visited {
  background-color: rgba(250, 252, 255, 0.25);
  border-radius: 4px;
  color: #fff;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected {
  background-color: rgba(250, 252, 255, 0.25);
  border-radius: 4px;
  font-weight: normal !important;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 8px;
  border-left: 4px solid;
}

.r-admin-sider
  .ant-menu:not(.ant-menu-horizontal)
  li.ant-menu-item-selected
  span
  a {
  font-weight: bold;
}

.r-admin-sider
  .ant-menu:not(.ant-menu-horizontal)
  li.ant-menu-item-selected::after {
  display: none;
}

.ant-layout-sider-collapsed.r-admin-sider ul li .ant-menu-item-group-title {
  visibility: hidden;
}

.ant-layout-sider-collapsed.r-admin-sider .logo .golfcloud-logo {
  width: 80px;
}

.ant-layout-sider-collapsed.r-admin-sider .logo img {
  margin: 0;
}

.r-admin-sider ul li .ant-menu-item-group-title {
  font-size: 12px;
}

.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item {
  padding: 0px calc(55% - 13px);
}

/* header design */
.r-admin-layout .r-admin-header {
  background: #fff;
  min-height: 70px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0 2px 4px -2px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 30px;
  z-index: 10;
}

.quick-access-title {
  font-size: 10px;
  text-transform: capitalize;
  margin-left: 20px;
  font-weight: normal;
}

.quick-create-event-btn {
  color: #047eb1;
  background: #cde5ef;
  font-size: 14px;
  font-weight: normal;
  border-radius: 3px;
  text-align: center;
  margin-right: 5px;
  margin-left: 20px;
  padding-top: 5px;
  padding-right: 20px;
  height: 40px;
  width: 170px;
  border: 1px solid #047eb1;
  line-height: 17px;
}

.quick-access-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-header {
  line-height: unset;
}

.r-admin-header-section {
  padding-left: 20px !important;
}

.r-admin-layout .r-admin-header .tiny-blue-btn {
}

.r-admin-layout .r-admin-header .collapse-btn {
  left: -12px;
  top: 12px;
}

.r-admin-layout .r-admin-header .tiny-blue-btn svg {
  height: 20px;
  width: 20px;
  fill: #047eb1;
  border-radius: 6px;
}

.r-admin-layout .r-admin-header .header-search-input {
  width: 320px;
  margin-left: 12px;
  border-radius: 4px;
}

.r-admin-layout
  .r-admin-header
  .header-search-input
  .ant-input-group-addon
  button {
  border-left: none;
}

/* For lower resolutions media queries */
@media (max-width: 992px) {
  .r-admin-layout .r-admin-header .header-search-input {
    width: 120px;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  flex-direction: column;
  height: 100%;
  padding-left: 12px !important;
}

.ant-menu-item a {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}

.ant-menu-item a:first-child {
  color: unset;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
}

.site-layout-background {
  margin: 30px 50px;
  margin-top: 60px;
  /* overflow-x: auto; */
}

.ant-menu-item .ant-menu-item-icon + span {
  margin-left: unset;
}

.ant-menu-inline > .ant-menu-item {
  line-height: 18px;
  margin-bottom: 12px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
}

.menu-style {
  display: "flex";
  flex: 1px;
  text-align: left;
  align-items: "center";
  /* color: "#FFF";use #fff */
  font-weight: "normal";
  padding-left: 3rem;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  cursor: "pointer";
  color: #fff;
  font-weight: 700;
  margin: 4px 2px;
  border: unset !important;
}

.menu-selected-style {
  background-color: black;
}

.menu-nonselected-style {
  box-shadow: unset !important;
  background-color: unset !important;
}

.menu-nonselected-style:hover {
  background-color: #419ec5 !important;
  font-weight: 900;
  color: #fff;
}

.menu-selected {
  display: "flex";
  flex: 1px;
  color: "#FFF";
  font-weight: "normal";
  padding-left: 24px;
  padding-top: 12px;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  cursor: "pointer";
  background-color: #419ec5;
  border-left: 96px solid;
  text-align: left;
  margin-top: 10px;
}

.padding-style {
  padding-left: 6px;
}

/* .ant-menu-inline .ant-menu-item:not() {
  margin-bottom: 27px;
} */

[ant-click-animating-without-extra-node="true"]::after {
  display: none;
}

.r-admin-sider-layout
  .ant-menu-inline.ant-menu-root
  .ant-menu-item
  > .ant-menu-title-content {
  color: #fff;
}

.ant-layout-sider-children {
  background-color: #212121;
  border-right: 5px solid #0abbf2 !important;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected {
  background: #0abbf2;
  box-shadow: 0px 0px 10px 5px rgba(0, 195, 255, 0.25);
  border-radius: 0px;
  border: none;
}

.r-admin-sider .ant-menu:not(.ant-menu-horizontal) li.ant-menu-item-selected {
  background: #0abbf2;
  box-shadow: 0px 0px 10px 5px rgb(0 195 255 / 25%);
  border-radius: 0px;
  border: none;
  margin: 0px;
  width: 100%;
  color: yellow !important;
}

.r-admin-sider-layout > .r-admin-sider .logo {
  border-bottom: 0.25px solid #0abbf2;
}

.ant-menu-item .ant-menu-item-selected .r-admin-sider ul .ant-menu-item svg {
  fill: #a0a0a0;
  /* background: #A0A0A0; */
}

.ant-menu-item .ant-menu-item-selected {
  background-color: black;
}

.sub-menu {
  margin-top: 25px;
  width: 100%;
  padding: 0px 0.25rem;
}

.BellOutlined {
  font-size: 28px;
}
.green-circle {
  background: #198658;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}
.footer-wrapper {
  font-size: 12px;
  background: rgb(252, 252, 252);
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc(100% - 110px);
  z-index: 9;
}
.footer-margin-gap {
  margin-top: 58px;
}
.mobile-footer-wrapper {
  width: 100vw !important;
}
.r-admin-header-section-mobile-menu {
  margin-left: -1.5rem;
}