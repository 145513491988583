.pairing-course-round-name {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  width: auto !important;
}

.pairing-course-round-name span {
  flex-shrink: 0;
}

.pairing-course-round-name>.selected {
  padding: 1rem 1.5rem;
  background-color: #e0eeff;
  border-bottom: 5px solid #047eb1;
  margin-bottom: -2px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 700;
  z-index: 9;
}

.pairing-course-round-name>.non-selected {
  padding: 1rem 1.5rem;
  margin-bottom: -2px;
}

.pairing-course-round-line {
  border-top: 1px solid #cccccc;
  margin-top: -2px;
}

.pairing-course-round-details-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.pairing-course-round-details-1 {
  width: 300px !important;
}
.color-square {
  height: 8px;
  width: 8px;
}

.color-square-wrapper {
  font-size: 10px;
  font-weight: 800;
}

.pairings-action-btn {
  margin: 0.5rem 1rem;
}

.pairing-action-row-btn {
  padding: 0 8px 8px 8px;
}

.pairing-action-row-btn>span {
  font-weight: 900;
  font-size: 2rem;
}

.pairing-action-row-btn>span>span>svg {
  width: 21px !important;
}

.pairing-action-row-btn>span>span {
  padding: 0px !important;
}

.pairing-action-row-btn-swap {
  padding: 4px 8px;
}

.overflowY-hidden {
  overflow-y: hidden !important;
}

.overflowX-hidden {
  overflow-x: hidden !important;
}

.h-60 {
  height: 60px;
}

.h-80p {
  height: 80px;
}

.w-120 {
  width: 120px;
}

.w-220 {
  width: 250px;
}

.bg-0076A8 {
  background-color: #0076a8;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.pairing-user-remove-btn {
  top: 0px;
  right: 0px;
}

.pairing-user-info-btn {
  top: 6px;
  left: -11px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.pairing-user-card-info>div {
  padding: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.pairing-action-btn {
  color: black !important;
}

.pairing-action-btn:hover {
  color: #047EB1 !important;
  background-color: #E0EEFF;
}

.pairing-sheet-scroll {
  max-height: calc(100vh);
  overflow-y: auto;
}

.dispaly-inherite {
  display: inherit !important;
}

.cursor-grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.cursor-grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.pointer-i {
  cursor: pointer !important;
}

.w-90 {
  width: 90%;
}



.allPlayersPagination .ant-pagination-item {
  border: none;
}

.allPlayersPagination .ant-pagination-item-active a,
.smooth-scrollable-container .ant-pagination,
.table_rangeBtn__NjRm7 {
  color: #0432AB !important;
}

.allPlayersPagination .ant-pagination-item a {
  color: #787A84;
}

.smooth-scrollable-container .ant-pagination {
  font-weight: bold;
  font-size: 12px;
}

.smooth-scrollable-container .players-header {
  border: 1px solid #A0A0A0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: none;
}

.smooth-scrollable-container .player-col-filter-menu,
.smooth-scrollable-container .search {
  border: 1px solid #0076A8;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  height: 35px;
  align-items: center;
}

.smooth-scrollable-container .anticon svg {
  color: #0076A8;
}

.smooth-scrollable-container .search__submit {
  background: none;
  font-size: unset;
}

.smooth-scrollable-container .search__submit svg {
  width: 15px;
  height: 15px;
  margin-top: 3px;
}

.smooth-scrollable-container .ant-table-thead>tr>th {
  background: #0076a8 !important;
  color: #fff;
  padding: 16px;
  font-weight: bold;
  font-size: 14px;
}

.manage-columns-container .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.manage-columns-container .ant-checkbox-wrapper span {
  color: #047EB1;
  font-weight: 900;
}

.manage-columns-container .ant-checkbox {
  border: 2px solid #0076A8;
  border-radius: 4px;
}


.manage-columns-container .ant-checkbox-checked .ant-checkbox-inner {
  background: #fff;
}

.manage-columns-container .ant-checkbox-checked .ant-checkbox-inner:after {
  border: 2px solid #047EB1;
  border-top: 0;
  border-left: 0;
}

.manage-columns-container .btns {
  text-align: center;
}

.manage-columns-container .btns .ant-btn-primary {
  background: #0076a8;
  border-radius: 4px;
  font-weight: bold;
}

.pairing-sheet-tee-time-btn {
  border-radius: 16px;
  padding: 0.25rem 1rem;
  border: 0px;
  margin: 1px;
  background-color: #fff;
}

.pairing-sheet-tee-time-btn:active {
  background-color: #047EB1 !important;
  color: #fff !important;
}

.pairing-sheet-tee-time-btn:hover {
  background-color: #047EB1 !important;
  color: white;
}

.pairing-sheet-tee-time {
  width: fit-content;
  border-radius: 16px;
}

.pairing-sheet-add-hole-wrapper {
  margin-top: 2rem;
  width: fit-content;
  padding: 1rem;
}

.h-60p {
  height: 50px;
}

.w-50p {
  width: 60px;
}

.w-80p {
  width: 80px;
}

.pairing-sheet-add-note {
  position: absolute;
  background-color: #fff;
  top: -12px;
  left: 8px;
  padding: 0px 4px;
  color: black;
}

.dnd-multidrag-view {
  width: 240px;
  height: 70px;
  left: -10px;
  top: -2px;
  display: none;
}

.dnd-multidrag-view-note {
  margin-left: 218px;
  font-size: 18px;
  margin-top: 46px;
}
.dnd-multidrag-allPlayer-view-note {
  font-size: 18px;
  position: absolute;
  right: -1.5rem;
}
/* np added temp css*/
.smooth-scrollable-container .ant-table-tbody tr {
  height: 60px;
}


.manage-columns-container .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d9d9d9 !important;
}

.manage-columns-container .ant-checkbox-wrapper-disabled span {
  color: #a7a1a1 !important;
  font-weight: bold;
}

.smooth-scrollable-container .ant-checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-columns-container .ant-checkbox-disabled .ant-checkbox-inner:after {
  border: 2px solid #a7a1a1;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
}

.name-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-column .anticon-more {
  margin-top: 4px;
}

.name-column .anticon svg {
  color: grey;
}

.manage-columns-container .ant-btn-text span {
  text-decoration: underline;
  color: #00000040;
  font-weight: bold;
}

.smooth-scrollable-container .ant-table-column-sorters .ant-table-column-sorter {
  background: #0076A8;
}

.smooth-scrollable-container .ant-table-column-sorters .ant-table-column-sorter svg,
.smooth-scrollable-container .anticon-filter svg {
  color: #fff;
}

.smooth-scrollable-container .ant-table-tbody>tr>td {
  /* border: 1px solid #d5d5d5; */
}

.smooth-scrollable-container .ant-checkbox-wrapper span {
  font-size: 14px;
  line-height: 1rem;
}

.manage-columns-container .ant-checkbox-wrapper {
  padding: 10px;
}

.ant-dropdown {
  width: 200px;
}

.playerInfoTooltip {
  color: #0076A8;
  padding: 10px;
}

.playerInfoTooltip .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  text-transform: capitalize;
}

.playerInfoTooltip p {
  margin-left: 5px;
}

.playerInfoTooltip p .ant-tag {
  color: black;
  background: #e3e2e2;
  font-weight: bold;
  border-color: #9ea09e;
  margin-top: 15px;
}

.smooth-scrollable-container .ant-table-tbody>tr>td:first-child {
  text-transform: capitalize;
}

.manage-columns-container .ant-checkbox-wrapper-disabled .ant-checkbox {
  border: 2px solid #a7a1a1;
}