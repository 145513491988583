/* dashboard stylings */

.fd-add-container .add-new-fd-image {
    width: 84px;
}

.fd-add-container .add-new-text {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
    margin-top: 20px;
}

.fd-add-container-data {
    min-height: 250px;
    border: 1px solid #A6A8AB;
}

.fd-add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 45px;
}

.fd-icon-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    align-items: center;
    gap: 20px;
}

.fd-search-icon {
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    background: #F0F0F0;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A0A0A0;
}

.fd-search-icon-container {
    margin-top: 7px;
}

.fd-search-icon-container .ant-input-affix-wrapper {
    height: 35px;
    color: #A0A0A0;
}

.fd-search-icon-container .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    border: 2px solid #A0A0A0;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    background: #F0F0F0;
    color: #A0A0A0;
}


.fd-icon {
    width: 24px;
    height: 24px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A0A0A0;
}

.fd-icon:hover,
.fd-icon:focus {
    cursor: pointer;
}

.fdcard-container {
    border: 1px solid #A6A8AB;
    height: 0;
    overflow: hidden;
    background: white;
    position: relative;
    padding-top: 56.25%;
    border-radius: 10px;
    min-height: 250px;
}

.fdcard-container .fdcard-container-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 32px;
}

.fdcard-container .ant-card-head {
    border: none;
}

.fdcard-container-inside .card-text {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    color: #6F6F6F;
}

.visibilityTab {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.274799px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 15px;
    border-radius: 2px;
    margin-right: 4px;
}

.publicTab {
    background-color: #CDFFCD;
    color: #24A524;
}

.nationalTab {
    background-color: #00B4FF;
    color: #FFFFFF;
}

.greyTab {
    background-color: #E0E0E0;
    color: #000000;
}

/* collapsable search icon styles  */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.content {
    width: 600px;
    position: relative;
}

.search {
    --size: 42px;
    border: 2px solid #A0A0A0;
    display: flex;
    overflow: hidden;
    font-size: 1em;
    position: relative;
    width: var(--size);
    height: var(--size);
    margin-left: auto;
    padding: 3px;

    @media (prefers-reduced-motion: no-preference) {
        transition: width 450ms;
    }
}

.search__input {
    border: 0;
    padding: .25em 1em;
    flex-grow: 1;
    outline: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent;
    opacity: 0;
    cursor: pointer;
}

.search__input::placeholder {
    color: #A0A0A0;
}

.search__submit {
    font-size: 1.5rem;
    margin-left: auto;
    background: #F0F0F0;
    border: 0;
    cursor: pointer;
    transition: background 200ms ease-out;
    width: calc(var(--size) - 10px);
    height: calc(var(--size) - 10px);
    color: #A0A0A0;
}

.search:focus-within {
    width: 100%;
}

.search__input {
    opacity: 1;
    z-index: initial;
    cursor: initial;
    width: calc(100% - var(--size));
}

.iconStyles {
    margin: 0px 4px 0px 4px;
}

.font-28 {
    font-size: 28px;
}

.scale1-1 {
    transform: scale(1.15);
}

.verticle-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}