.NotificationBellCenterModal {
    display: flex;
    flex-direction: column !important;
    position: fixed;
    top: 60px;
    right: 34px;
}

.ant-modal-body {
    display: flex;
    flex-direction: column;
}

.ant-modal-footer {
    display: none !important;
}

.NotificationBellCenterModal .ant-modal-close {
    border: 1px solid #A6A8AB;
    border-radius: 50%;
    margin-right: 28px;
    margin-top: 36px;
}

.NotificationBellCenterModal .ant-modal-close-x {
    display: flex;
    width: 18px;
    height: 18px;
    font-size: 10px;
    font-style: normal;
    align-items: center;
    justify-content: center;
}

.notificationCenterButton,
.notificationCenterButton:focus,
.notificationCenterButton:hover {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80px;
    height: 28px;
    border-radius: 4px;
    border: none;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15.12px;
}

.notificationCenterButton .ant-btn>span {
    margin-left: 12px;
}


.NotificationRow .ant-btn {
    padding: unset;
}


.NotificationListEventName {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 14px;
    line-height: 17.6px;
}

.notificationCenterFilterSection {
    display: flex;
    justify-content: space-evenly;
    border: 0.5px solid #047EB1;
    border-radius: 4px;
    width: 280px;
    height: 34px;
    align-items: center;
}

.NotificationRow {
    display: flex;
    justify-Content: space-between;
    width: 100%;
    margin-Top: 24px;
    margin-bottom: 24px;
}

.notificationCenterFilterButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 4px;
    border: none;
    color: #959595;

}

.notificationCenterFilterButtonActive {
    background-color: #047EB1 !important;
    color: white !important;
    text-decoration: underline !important;
    padding: 2px;
    font-weight: bold;
}

.selectedViewButton,
.selectedViewButton:hover,
.selectedViewButton:focus {
    width: 140px;
    background-color: #E2E2E2 !important;
    color: #636363;
    font-weight: 700 !important;
}

.selectedApproveButton,
.selectedApproveButton:hover,
.selectedApproveButton.focus {
    width: 140px;
    background-color: #C6EFC6 !important;
    color: #198658;
    font-weight: 700 !important;

}

.selectedCancelButton,
.selectedCancelButton:focus,
.selectedCancelButton:hover {
    width: 140px;
    background-color: #ffdce0 !important;
    color: #BF0000;
    font-weight: 700 !important;
}

.notificationCenterTable {
    border: 1px solid #D5D5D5;
    margin-top: 30px;
}

.notificationCenterTable .ant-table-container table>thead>tr:first-child th:first-child {
    background: #0076A8;
}

.notificationCenterTable .ant-table-thead>tr>th {
    background: #0076A8;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
    line-height: 16px;
}

.notificationCenterTable .ant-table-container table>thead>tr:first-child th:last-child {
    width: 140px;
    background: #0076A8;
    color: #ffffff;
}

.notificationCenterTitle {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    line-height: 22px;
}

.notificationCenterTitleIcon {
    margin-top: 8px;
    margin-left: -10px;
}
.NotificationBellCenterModal .anticon {
    padding-left: 0px;
}

.notificationCenterDropDownButton,
.notificationCenterDropDownButton:focus,
.notificationCenterDropDownButton:hover {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    border-radius: 4px;
    border: none;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15.12px;
}

.notificationCenterDropDownButton svg {
    margin-right: 12px;
}

.NotificationClass {
    font-weight: bold;
}