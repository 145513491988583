.modal-subtitle {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 24px;
}

.modal-title {
    margin-top: 30px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 32px;
}

.payout-matrix-modal {
    min-width: 70% !important;
    max-width: auto !important;
}

.payout-matrix-modal .ant-modal-close-x {
    border: 1px solid grey;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 20px 0px 0px;
}

.payout-matrix-modal .ant-modal-close-x svg {
    width: 0.8em;
}

.payout-matrix-modal .ant-modal-close {
    left: 20px;
    margin-bottom: 30px;
    margin-left: 30px;
}

.payout-matrix-modal .modal-text {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-top: 16px;
}

.payout-matrix-table {
    text-align: center;
    margin: 30px 0px;
}

.payout-matrix-table .formik-payout-matrix-table-body {
    font-size: 12px !important;
}

.payout-matrix-modal .ant-modal-body {
    margin: 30px !important;
}

.payout-matrix-table-curve {
    border-top-left-radius: 5px !important;
    padding: 0px 6px;
    white-space: nowrap;
}

.table-header-cell {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.payout-matrix-table th {
    border: 1px solid #000000;
    border-radius: 5px 5px 0px 0px;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 21px;
    color: #004992;
}

.payout-matrix-table th,
.payout-matrix-table td {
    height: 30px;
    width: 100px;
    border: 1px solid #000000;
    border-radius: 4px;
}

.payout-matrix-table th,
.payout-matrix-table td input {
    height: 30px;
    border: 1px solid #000000;
    border-radius: 4px;
    font-weight: 800;
}

.payout-matrix-table tr td:first-child {
    border-radius: 5px 5px 0px 0px;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 21px;
    color: #004992;
}

.payout-matrix-table-cell {
    padding: 10px;
    position: relative;
    font-family: 'Montserrat';
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
}

.payout-matrix-table-close-button,
.payout-matrix-table-close-button:hover,
.payout-matrix-table-close-button:focus {
    width: 125px;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}