.user-table {
  padding: 20px;
  font-family: "Plus Jakarta Sans";
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(0, 82, 128, 0.25);
  font-family: "Plus Jakarta Sans";
}

.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  font-size: 24px;
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
}

.table-container {
  /* background: #FFFFFF;
  box-shadow: 0 -1px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1px;
  font-family: "Plus Jakarta Sans";
}

.searchbar-and-button-container {
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Plus Jakarta Sans";
}

.table-input {
  font-size: 12px;
  height: 50px;
  background: #E3F0FF !important;
  border-radius: 1px solid #047EB1;
  color: #047eb1;
  width: 400px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
}

.ant-input-affix-wrapper>.ant-input:not(textarea) {
  /* background-color: #E3F0FF; */
}

@media (max-width: 836px) {
  .table-input {
    width: unset;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
  }
}

.table-input ::placeholder {
  color: #047eb1;
  font-size: 12px;
  font-family: "Plus Jakarta Sans";
}


.csv-button {
  background: #ffffff;
  border-radius: 3px;
  height: 40px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: #004C98;
  border: 1px solid #004C98;
  font-family: "Plus Jakarta Sans";
}


.table-container.ant-input-affix-wrapper>input.ant-input {
  background: #cde5ef;
}

.ant-table-thead th.ant-table-column-has-sorters {
  background: #cde5ef;
  color: #047eb1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #cde5ef;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  background: #cde5ef;
}

.changes-button-container {
  width: 100px;
  height: 40px;
}

.changes-button {
  border: 0px;
  background: #ffffff;
  cursor: pointer;
  color: #004992;
  font-style: normal;
  font-size: 12px;
  font-family: Inter;
  width: 150px;
  text-align: start;
  font-family: 'Montserrat';
  font-weight: 900;
  line-height: 15px;
  letter-spacing: 0.03em;

}

.changes-button:hover {
  background: #cde5ef;
}

.changes-container {
  border: 0px;
  background-color: #cde5ef;
  color: #047eb1;
  cursor: pointer;
}

.ant-table-thead>tr:first-child th:last-child {
  background: #cde5ef;
}

.ant-popover-inner-content {
  padding: unset;
}

.ant-table-column-sorter,
.ant-table-column-sorter:hover {
  color: unset;
  font-weight: 400;
}

.ant-table-thead>tr>th {
  background: #cde5ef;
  color: #047eb1;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
}

.error-text span {
  color: red;
}

.rejection-modal .ant-form-item-explain-error {
  font-size: 12px;
  margin-top: 0.8rem;
}

.img-preview-btn,
.img-preview-btn:hover,
.img-preview-btn:focus,
.img-preview-btn:after {
  background: unset;
  color: unset;
  border: unset;
  text-decoration: underline !important;
  box-shadow: unset;
  text-shadow: unset;
  transition: unset;
}

.ant-table-tbody>tr>td {
  padding: 8px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
}

.ant-table-cell-ellipsis {
  white-space: unset;
  text-overflow: unset;
  text-align: center;
  width: 140px;
}

.ant-table-cell-ellipsis .ant-table-column-title {
  font-weight: 600;
}

.ant-table-thead th.ant-table-column-has-sorters {
  padding: 8px;
  text-align: center;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  width: 140px;
}

.ant-modal-header {
  padding-top: 30px;
  padding-bottom: 0;
  border-bottom: none;
  border-radius: 15px;
}

.ant-modal-content {
  border-radius: 10px;
}

.buttons-container {
  text-align: center;
}

.submit-btn {
  margin-left: 6px;
  background: #047eb1;
  width: 100px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
}

.submit-btn:hover {
  background: #047eb1;
}

.scores-container {
  margin-top: 20px;
}

.scores-container .score {
  border: 0.5px solid #047eb1;
  border-radius: 3px;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 10px;
  width: 140px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Plus Jakarta Sans";
}

.score-modal-img-container {
  margin-top: 20px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans";
}

.ant-tag-green {
  color: #198658;
  background: #ceeee1;
  border-color: #198658;
  font-family: "Plus Jakarta Sans";
}

.rejection-modal-heading,
.scores-rejected-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  font-family: "Plus Jakarta Sans";
}

.scores-rejected-text {
  margin: 0;
  color: #cf1322;
  font-weight: 400;
}

.ant-image-preview-img {
  width: 300px;
}

.edit-btn,
.reject-btn {
  background: #047eb1;
  color: #fff;
  padding: 10px;
  height: max-content;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  font-family: "Plus Jakarta Sans";
}

.reject-btn {
  background: red;
}

.reject-btn:hover {
  color: #fff;
  background: #da3e3e;
}

.edit-btn:hover {
  background: #047eb1;
  color: #fff;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.grey-txt span {
  color: #8b8383;
}

.transactionModal {
  width: 50% !important;
}

.ant-descriptions-title {
  display: block;
  text-align: start;
  font-size: 24px;
  font-weight: 600;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: #fff;
  width: 40%;
  font-size: 16px;
  border-right: 0.5px solid #000000;
  font-family: "Plus Jakarta Sans";
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 0.5px solid #000000;
}

.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 0.5px solid #000000;
}

.transactionModal .ant-modal-close-x svg {
  stroke: black;
  margin-top: 27px;
}

.transactionModalCancelBtn {
  border: 1px solid #5e5e5e;
  border-radius: 3px;
  margin-top: 25px;
  color: #5e5e5e;
  font-size: 14px;
  width: 148px;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown,
.ant-table-filter-dropdown-btns {
  border-radius: 6px;
}

.table-container.ant-input-affix-wrapper>input.ant-input,
.table-container.ant-input-affix-wrapper {
  background: #ecf5ff;
  border-radius: 6px;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper+span {
  color: #047eb1;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown-search.ant-input {
  color: red;
}

.table-input.ant-input-affix-wrapper>input.ant-input,
.table-input.ant-input-affix-wrapper ::placeholder {
  color: #047eb1 !important;
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.ant-table-filter-dropdown-search-input .anticon {
  color: #047eb1;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.sampletable-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.sampletable-checkbox-background .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green;
  border-color: green;
}

.container {
  position: relative;
}

.black-box {
  position: relative;

  /* height: 100px; */
  /* margin: 30px; */
}

.gray-box {
  position: absolute;
  /* background: lightgray; */
  /* height: 60px;   */
  /* width: 50%; */
  /* left: 30px; */
  top: 4px;
}

.tag-type {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  margin-right: -5px;
  /* padding-left: 1.75rem; */
  /* identical to box height */
  cursor: pointer;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 76px;
  color: #FFFFFF;
}

.trimmed-tag-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px
}

.sub-heading {
  display: flex;
  margin-left: 85%;
  margin-bottom: 10px;
}

.orange-box {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background: #F28F1B;
  margin-top: 1px;
  margin-right: 5px;


}

.blue-box {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background: #378FF8;
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 10px;

}

.tag-name {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 13px; */
  letter-spacing: 0.05em;
  text-transform: capitalize;

  color: #000000;

}

.ant-table-cell-ellipsis {
  width: 135px;
}

.ant-table-tbody>tr>td {
  padding-top: 5px;
}

.search-option {
  background: #E3F0FF;
  border: 0px solid #047EB1;
  border-radius: 6px 0px 0px 6px;
  height: 50px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  /* line-height: 15px; */
  color: #047EB1;
  padding-left: 10px;
  padding-right: 10px;
}

.search-input {
  background: #E3F0FF;
  border: 0px solid #047EB1;
  height: 50px;
  width: 280px;
  color: #047EB1;
  padding-left: 10px;
  border-left: 1px solid #047EB1;
  margin-left: 10px;


}

::placeholder {
  color: #047EB1;
  opacity: 1;
  /* Firefox */
}

.input-search {
  border: 1px solid #047EB1;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  background-color: #E3F0FF;

}

.search-icon {
  background-color: #E3F0FF;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 11px;
  margin-right: 15px;
}

/* player Tag Styles */
.PlayerTagText {
  font-size: 12px;
  font-family: Plus Jakarta Sans;
  font-weight: 700;
  color: #047EB1;
  line-height: 15.12px;
  align-self: center;
  letter-spacing: 3%;
  display: flex;
  align-items: center;
}

.PlayerTagTypeText {
  font-size: 12px;
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  color: #047EB1;
  line-height: 15.12px;
  align-self: center;
  border: none;
  text-decoration: underline;
  letter-spacing: 3%;
}

.PlayerTagText .ant-checkbox-input {
  color: #047EB1;
  background-color: none;
}

.PlayerTagText .ant-checkbox {
  border: 1px solid #047EB1;
  border-radius: 2px;
  margin: 4px;
}


/* playerTag modal styles */
.playerTag-heading-section {
  font-weight: 700;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  color: #047EB1;
  margin-bottom: 20px;
}

.TagList {
  padding: 10px;
}

.newPopoverClass {
  display: none;
}

.player-tags-icons-section {
  display: flex;
  justify-content: end;
  margin-top: 4px;
  align-items: center;
}

.create-tag-container {
  border-radius: none;
}

.text-field-label-custom {
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 12px;
  margin-bottom: 12px;
}

.text-field-label {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 12px;
}

.PlayerTagText .ant-checkbox-wrapper span {
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
}

.create-tag-container .ant-modal-footer {
  padding: 30px 0px;
  margin-left: 16px;
}

.create-tag-container .ant-picker-suffix svg {
  width: 10px;
}

.tagTooltipSection {
  width: 240px;
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  max-height: 100px;
  overflow: auto;
}

.tagImageSection {
  width: 33px;
  border-radius: 50%;
}

.addTagClass {
  position: absolute;
  margin-left: 12px;
}

.PlayerTagText .ant-checkbox {
  top: unset
}