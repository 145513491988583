.upload-button {
  display: flex;
  border-radius: 4px;
  border-color: #cce6ff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-decoration-line: underline;
  text-transform: uppercase;

  color: #004992;
}

.img-div {
  border: 1px dashed #000000;
  border-radius: 40px;
}
.membership-imageupload-description {
  text-align: left;
}
.membership-imageupload-description .upload-text {
  font-weight: 800;
  padding: 0;
}
.membership-imageupload-description .upload-hint {
  font-weight: 400;
  padding: 8px 0px;
}
.upload-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding-left: 5px;
}

.upload-hint {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
  padding-top: 10px;
  padding-right: 70px;
  padding-left: 5px;
}
.upload-image-wrapper {
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: space-between;
  margin: 1rem 2rem;
}
.upload-image-wrapper-hide-text .ant-upload.ant-upload-drag .ant-upload{
  padding: 1rem 0px !important;
}
