.tableStyle {
    background: #CDE5EF;
    color: #047EB1;
    font-weight: bold;
    height: 45px;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    margin: 0px !important;
}

.tableHeader {
    font-weight: 700 !important;
    font-family: Plus Jakarta Sans;
    color: #047EB1 !important;
    font-size: 32px !important;
}

.editBtn {
    color: #047EB1 !important;
    background: #E3F0FF !important;
    text-align: left !important;
}

.deleteBtn {
    color: rgb(255, 75, 75) !important;
    text-align: left !important;
    border: unset !important;
}

.deleteBtn:hover {
    border: unset;
    color: rgb(255, 2, 2) !important;
}

.rangeContainer {
    padding: 2px;
    display: flex;
    flex-direction: column;
}

.rangeBtn {
    min-width: 50px;
    margin: 2px;
    border: 0px !important ;
    background-color: transparent !important;
}