.card-container {
    border: 1px solid #A6A8AB;
    height: 0;
    overflow: hidden;
    background: white;
    position: relative;
    padding-top: 56.25%;
    border-radius: 4px;
    min-height: 250px;
}

.card-text {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
}
.card-container .card-container-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
}

.card-container .ant-card-head {
    border: none;
}

.card-container .card-footer {
    position: absolute;
    bottom: 16px;
    right: 12px
}

.card-footer .card-edit-btn,
.card-edit-btn:hover {
    color: #047EB1;
    border: 2px solid #047EB1;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 4px;
    /* padding: 10px 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 92px;
}

.card-footer .card-pending-btn,
.card-pending-btn:hover {
    color: orange;
    border: 2px solid orange;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 4px;
    /* padding: 10px 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 188px;
}
.ant-card-head-title {
    font-family: Plus Jakarta Sans;
    font-weight: Bold;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    justify-content: space-between;
}
.assign-btn {
    border: unset;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #000000;
}